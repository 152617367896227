<template>
  <div>
    <div class="d-flex justify-content-end mb-3">
      <base-button variant="primary" text="Cetak" @click="print" />
    </div>
    <div id="print-section">
      <section>
        <Pengiriman :dropping-data-sender-info="droppingData" />
      </section>

      <section class="my-5 html2pdf__page-break">
        <PemantauanSuhu :dropping-temperature-watch="droppingData" />
      </section>

      <section>
        <Lampiran :dropping-info-blood="droppingData" />
      </section>
    </div>
  </div>
</template>

<script>
import Pengiriman from "./components/Pengiriman.vue";
import Lampiran from "./components/Lampiran.vue";
import PemantauanSuhu from "./components/PemantauanSuhu.vue";
import manageDroppingAPI from "../../../../../api/dropping/manageDroppingAPI";
import html2pdf from "html2pdf.js";

export default {
  name: "File",
  components: {
    Pengiriman,
    Lampiran,
    PemantauanSuhu,
  },
  data() {
    return {
      droppingData: {
        no_dropping: "",
        date: "",
        ice_pack: 0,
        hospital_blood_receivers: {
          name: "",
          email: "",
          phone: "",
          address: "",
        },
        components: [],
      },
    };
  },
  created() {
    this.fetchDetail();
  },
  methods: {
    async fetchDetail() {
      try {
        const { data: response } = await manageDroppingAPI.getDetail(
          this.$route.params.id
        );

        this.droppingData = {
          no_dropping: response.data.no_dropping,
          date: response.data.date,
          ice_pack: response.data.ice_pack,
          hospital_blood_receivers: {
            name: response.data.hospital_blood_receivers.name || "",
            email: response.data.hospital_blood_receivers.email || "",
            phone: response.data.hospital_blood_receivers.phone || "",
            address: response.data.hospital_blood_receivers.address || "",
          },
          components: response.data.components,
        };
      } catch (error) {
        console.error("Error ketika ambil data", error);
      }
    },

    print() {
      const element = document.getElementById("print-section");
      const opt = {
        margin: 0,
        filename: `${this.droppingData.date}_${this.droppingData.hospital_blood_receivers.name}.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "portrait",
        },
      };
      html2pdf().set(opt).from(element).save();
    },
  },
};
</script>
