<template>
  <div class="container-a4 bg-white p-5 rounded shadow-sm">
    <h4 class="text-center mb-3">Formulir Pemantauan Suhu</h4>
    <table class="table table-sm border">
      <tr>
        <th scope="col" style="width: 25%">Nomor Dokumen</th>
        <th scope="col" style="width: 25%">Nomor Revisi</th>
        <th scope="col" style="width: 25%">Tgl Terbit</th>
        <th scope="col" style="width: 25%">Tgl Revisi</th>
      </tr>
      <tr>
        <td>UTD-PTD-01</td>
        <td>00</td>
        <td>{{ droppingTemperatureWatch?.date }}</td>
        <td>{{ droppingTemperatureWatch?.date }}</td>
        <td>-</td>
      </tr>
    </table>
    <section>
      <table class="table table-sm table-striped border">
        <!-- Jenis Penyimpanan -->
        <tr>
          <th scope="row" style="width: 25%">Jenis Penyimpanan</th>
          <td style="width: 5%">:</td>
          <td colspan="5">
            <div class="form-check-inline">
              <BFormCheckbox>WB</BFormCheckbox>
            </div>
            <div class="form-check-inline">
              <BFormCheckbox>PRC</BFormCheckbox>
            </div>
            <div class="form-check-inline">
              <BFormCheckbox>TC</BFormCheckbox>
            </div>
            <div class="form-check-inline">
              <BFormCheckbox>FFP</BFormCheckbox>
            </div>
            <div class="form-check-inline">
              <BFormCheckbox>AHF</BFormCheckbox>
            </div>
          </td>
        </tr>

        <!-- Jenis Fasilitas -->
        <tr>
          <th scope="row">Jenis Fasilitas</th>
          <td>:</td>
          <td colspan="5">
            <div class="form-check-inline">
              <BFormCheckbox>Cool Box Kecil</BFormCheckbox>
            </div>
            <div class="form-check-inline">
              <BFormCheckbox>Cool Box Sedang</BFormCheckbox>
            </div>
            <div class="form-check-inline">
              <BFormCheckbox>Cool Box Besar</BFormCheckbox>
            </div>
          </td>
        </tr>

        <!-- Rentang Suhu Transportasi -->
        <tr>
          <th scope="row">Rentang Suhu Transportasi</th>
          <td>:</td>
          <td>
            <div>
              <BFormCheckbox>2-10 °C</BFormCheckbox>
            </div>
          </td>
          <td>
            <div>
              <BFormCheckbox>20-24 °C</BFormCheckbox>
            </div>
          </td>
          <td>
            <div>
              <BFormCheckbox>< 25 °C</BFormCheckbox>
            </div>
          </td>
          <td colspan="2">
            <label class="form-label">Lainnya:</label>
            <BFormInput type="number" placeholder="Masukkan suhu lainnya" />
          </td>
        </tr>

        <!-- Pengiriman dari UTDRS ke -->
        <tr>
          <th scope="row">Pengiriman dari UTDRS ke</th>
          <td>:</td>
          <td colspan="5">
            {{ droppingTemperatureWatch?.hospital_blood_receivers?.name }}
            {{ droppingTemperatureWatch?.hospital_blood_receivers?.name }}
          </td>
        </tr>
      </table>

      <table class="table table-sm text-center">
        <tr>
          <thead>
            <tr>
              <th style="width: 25%; border: 1px solid black">
                Jam Pemeriksaan
              </th>
              <th style="width: 25%; border: 1px solid black">Suhu</th>
              <th style="width: 25%; border: 1px solid black">Petugas 1</th>
              <th style="width: 25%; border: 1px solid black">Petugas 2</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in 5" :key="index">
              <td style="border: 1px solid black; height: 25px" />
              <td style="border: 1px solid black" />
              <td style="border: 1px solid black" />
              <td style="border: 1px solid black" />
            </tr>
          </tbody>
        </tr>
      </table>

      <div class="d-flex justify-content-between">
        <div>
          <p class="mb-0">
            <strong><u>Catatan</u></strong>
          </p>
          <table>
            <tr>
              <th>Jumlah Darah</th>
              <td class="pl-1">: {{ totalBloodBags() }}</td>
            </tr>
            <tr>
              <th>Jumlah Ice Pack</th>
              <td class="pl-1">: {{ droppingTemperatureWatch?.ice_pack }}</td>
            </tr>
          </table>
        </div>
        <div>
          <p class="text-left" style="width: 400px">Tanggal:</p>

          <p class="text-center">Petugas UTD RSUD OTISTA</p>
          <div style="height: 80px" />
          <p class="text-center">
            ( ............................................................... )
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { BFormCheckbox, BFormInput } from "bootstrap-vue";

export default {
  name: "PemantauanSuhu",
  components: {
    BFormCheckbox,
    BFormInput,
  },
  props: {
    droppingTemperatureWatch: {
      type: Object,
      default: () => ({
        ice_pack: 0,
        components: [],
      }),
    },
  },
  data() {
    return {
      bloodComponents: [
        { name: "PRC NAT" },
        { name: "PRC NON NAT" },
        { name: "PCL" },
        { name: "AHF" },
        { name: "TC NON NAT" },
        { name: "FFP NAT" },
        { name: "FFP NON NAT" },
        { name: "PCR-LR" },
      ],
    };
  },
  watch: {
    droppingTemperatureWatch: {
      immediate: true,
      deep: true,
    },
  },
  methods: {
    totalBloodBags() {
      return this.bloodComponents.reduce((total, component) => {
        const baseComponent = component.name.split(" ")[0];

        if (component.name.includes("NON NAT")) {
          const count = this.droppingTemperatureWatch.components.filter(
            (comp) => comp.component.includes(baseComponent)
          ).length;

          return total + count;
        }

        return total;
      }, 0);
    },
  },
};
</script>
