<template>
  <div class="bg-white p-5 rounded shadow-sm">
    <h4 class="text-center">Formulir Pengiriman Darah</h4>

    <!-- Informasi Pengiriman -->
    <section class="float-right">
      <table>
        <tr>
          <td>Nomor Pengiriman</td>
          <td>: {{ droppingDataSenderInfo.no_dropping }}</td>
        </tr>
        <tr>
          <td>Tanggal Pengiriman</td>
          <td>: {{ droppingDataSenderInfo.date }}</td>
        </tr>
      </table>
    </section>

    <!-- Informasi Penerima -->
    <section class="mt-4">
      <table>
        <tr>
          <td>Untuk UDD/UTDRS/UDDRS</td>
          <td>
            : {{ droppingDataSenderInfo?.hospital_blood_receivers?.name }}
          </td>
        </tr>
        <tr>
          <td>Alamat</td>
          <td>
            : {{ droppingDataSenderInfo?.hospital_blood_receivers?.address }}
          </td>
        </tr>
        <tr>
          <td>Nomor Telepon</td>
          <td>
            : {{ droppingDataSenderInfo?.hospital_blood_receivers?.phone }}
          </td>
        </tr>
        <tr>
          <td>Pemesanan Via</td>
          <td>: -</td>
        </tr>
      </table>
    </section>

    <!-- Tabel Pengiriman Darah -->
    <section class="mt-3">
      <table class="table table-sm table-striped text-center">
        <thead>
          <tr class="p-1">
            <th class="p-1" rowspan="2">No</th>
            <th class="p-1" rowspan="2">Jenis Komponen Darah</th>
            <th class="p-1" colspan="4">Golongan Darah</th>
            <th class="p-1" rowspan="2">Jumlah Kantong</th>
            <th class="p-1" rowspan="2">Keterangan</th>
          </tr>
          <tr class="p-1">
            <th class="p-1">A</th>
            <th class="p-1">B</th>
            <th class="p-1">AB</th>
            <th class="p-1">O</th>
          </tr>
        </thead>
        <tbody>
          <tr class="p-1" v-for="(item, index) in bloodComponents" :key="index">
            <td class="p-1">{{ index + 1 }}</td>
            <td class="text-left p-1">
              {{ item.name }}
            </td>
            <td class="p-1">
              {{ countByComponentAndBloodType(item.name, "A") || "-" }}
            </td>
            <td class="p-1">
              {{ countByComponentAndBloodType(item.name, "B") || "-" }}
            </td>
            <td class="p-1">
              {{ countByComponentAndBloodType(item.name, "AB") || "-" }}
            </td>
            <td class="p-1">
              {{ countByComponentAndBloodType(item.name, "O") || "-" }}
            </td>
            <td class="p-1">{{ totalBloodBags(item.name) || "-" }}</td>
            <td class="p-1">-</td>
          </tr>
        </tbody>
      </table>
    </section>

    <!-- Validasi Pengiriman -->
    <section class="mt-3">
      <p class="mb-0">Validasi Pengiriman Darah</p>
      <table
        class="text-center"
        style="border: 1px solid black; border-collapse: collapse"
      >
        <tr>
          <th style="border: 1px solid black; width: 225px">
            Manager Tekniks Pelayanan Darah
          </th>
          <th style="border: 1px solid black; width: 225px">
            Bidang Distribusi
          </th>
          <th style="border: 1px solid black; width: 225px">
            Bidang Prolis & Penyimpanan
          </th>
        </tr>
        <tr>
          <td style="border: 1px solid black; height: 80px" />
          <td style="border: 1px solid black; height: 80px" />
          <td style="border: 1px solid black; height: 80px" />
        </tr>
      </table>
    </section>

    <!-- Pencatatan Suhu Transportasi -->
    <section class="mt-3">
      <p class="mb-0">Pencatatan Suhu Transportasi</p>
      <table
        class="text-center"
        style="border: 1px solid black; border-collapse: collapse"
      >
        <thead>
          <tr>
            <th style="border: 1px solid black; width: 75px">Jam</th>
            <th style="border: 1px solid black; width: 75px">Suhu</th>
            <th style="border: 1px solid black; width: 75px">Petugas 1</th>
            <th style="border: 1px solid black; width: 75px">Petugas 2</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="i in 5" :key="i">
            <td style="border: 1px solid black; height: 30px" />
            <td style="border: 1px solid black; height: 30px" />
            <td style="border: 1px solid black; height: 30px" />
            <td style="border: 1px solid black; height: 30px" />
          </tr>
        </tbody>
      </table>
    </section>

    <!-- Tanda Terima Pengiriman Darah -->
    <section class="mt-3">
      <p class="mb-0">Tanda Terima Pengiriman Darah</p>
      <table
        class="text-center"
        style="border: 1px solid black; border-collapse: collapse"
      >
        <tr>
          <th style="border: 1px solid black">
            Petugas Pengiriman Darah <br />
            UTD RSUD OTISTA
          </th>
          <th style="border: 1px solid black">Petugas UDD/UTDRS/BDRS</th>
        </tr>
        <tr>
          <td style="border: 1px solid black; width: 225px; height: 80px" />
          <td style="border: 1px solid black; width: 225px; height: 80px" />
        </tr>
      </table>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    droppingDataSenderInfo: {
      type: Object,
      default: () => ({
        no_dropping: "",
        date: "",
        hospital_blood_receivers: {
          name: "",
          email: "",
          phone: "",
          address: "",
        },
        components: [],
      }),
    },
  },
  data() {
    return {
      bloodComponents: [
        { name: "PRC NAT" },
        { name: "PRC NON NAT" },
        { name: "PCL" },
        { name: "AHF" },
        { name: "TC NON NAT" },
        { name: "FFP NAT" },
        { name: "FFP NON NAT" },
        { name: "PCR-LR" },
      ],
    };
  },
  watch: {
    droppingDataSenderInfo: {
      immediate: true,
      deep: true,
    },
  },
  methods: {
    countByComponentAndBloodType(componentName, bloodType) {
      const baseComponent = componentName.split(" ")[0];

      // Filter components array based on matching component and blood type
      return this.droppingDataSenderInfo.components.filter(
        (component) =>
          component.component.includes(baseComponent) &&
          component.blood_type_result.split(" ")[0] === bloodType &&
          componentName.includes("NON NAT")
      ).length;
    },
    totalBloodBags(componentName) {
      // Count the total number of blood bags for each "NON NAT" component
      const baseComponent = componentName.split(" ")[0];
      return this.droppingDataSenderInfo.components.filter(
        (component) =>
          component.component.includes(baseComponent) &&
          componentName.includes("NON NAT")
      ).length;
    },
  },
};
</script>
