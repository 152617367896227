<template>
  <div class="bg-white rounded shadow-sm py-5 px-2">
    <h4 class="text-center">
      LAMPIRAN NOMOR KANTONG DARAH DROPPING RS <br />
      UNIT TRASFUSI DARAH RSUD OTO ISKANDAR DINATA <br />
      TANGGAL, {{ formattedDate(droppingInfoBlood.date) }}
    </h4>

    <table
      class="table table-sm table-striped text-center"
      style="font-size: 0.7rem; border: 1px solid black"
    >
      <thead>
        <tr>
          <th
            rowspan="2"
            style="
              border: 1px solid gray;
              vertical-align: middle;
              text-align: center;
            "
          >
            No
          </th>
          <th
            rowspan="2"
            style="
              border: 1px solid gray;
              vertical-align: middle;
              text-align: center;
            "
          >
            Nomor Kantong
          </th>
          <th
            rowspan="2"
            style="
              border: 1px solid gray;
              vertical-align: middle;
              text-align: center;
            "
          >
            Nomor Selang
          </th>
          <th
            rowspan="2"
            style="
              border: 1px solid gray;
              vertical-align: middle;
              text-align: center;
            "
          >
            Gol. Darah
          </th>
          <th
            rowspan="2"
            style="
              border: 1px solid gray;
              vertical-align: middle;
              text-align: center;
            "
          >
            Komponen <br />
            Darah
          </th>
          <th
            rowspan="2"
            style="
              border: 1px solid gray;
              vertical-align: middle;
              text-align: center;
            "
          >
            Volume <br />
            (CC)
          </th>
          <th
            rowspan="2"
            style="
              border: 1px solid gray;
              vertical-align: middle;
              text-align: center;
            "
          >
            Tgl. Pengambilan <br />
            Darah
          </th>
          <th
            rowspan="2"
            style="
              border: 1px solid gray;
              vertical-align: middle;
              text-align: center;
            "
          >
            Tgl. Kadaluarsa <br />
            Darah
          </th>
          <th
            rowspan="2"
            style="
              border: 1px solid gray;
              vertical-align: middle;
              text-align: center;
            "
          >
            Suhu
          </th>
          <th
            rowspan="2"
            style="
              border: 1px solid gray;
              vertical-align: middle;
              text-align: center;
            "
          >
            Tgl. Pemeriksaan <br />
            Darah
          </th>
          <th
            colspan="4"
            style="
              border: 1px solid gray;
              vertical-align: middle;
              text-align: center;
            "
          >
            Hasil Skrining
          </th>
          <th
            rowspan="2"
            style="
              border: 1px solid gray;
              vertical-align: middle;
              text-align: center;
            "
          >
            Tujuan
          </th>
          <th
            rowspan="2"
            style="
              border: 1px solid gray;
              vertical-align: middle;
              text-align: center;
            "
          >
            Keterangan
          </th>
        </tr>
        <tr>
          <th
            style="
              border: 1px solid gray;
              vertical-align: middle;
              text-align: center;
            "
          >
            Anti-HIV
          </th>
          <th
            style="
              border: 1px solid gray;
              vertical-align: middle;
              text-align: center;
            "
          >
            Anti-HcV
          </th>
          <th
            style="
              border: 1px solid gray;
              vertical-align: middle;
              text-align: center;
            "
          >
            HBsAg
          </th>
          <th
            style="
              border: 1px solid gray;
              vertical-align: middle;
              text-align: center;
            "
          >
            Sifilis
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in droppingInfoBlood.components" :key="index">
          <td style="border: 1px solid black">
            {{ index + 1 }}
          </td>
          <td style="border: 1px solid black">
            {{ item.component_barcode }}
          </td>
          <td style="border: 1px solid black">
            {{ item.blood_bag_number }}
          </td>
          <td style="border: 1px solid black">
            {{ item.blood_type_result }}
          </td>
          <td style="border: 1px solid black">
            {{ item.component }}
          </td>
          <td style="border: 1px solid black">
            {{ item.volume }}
          </td>
          <td style="border: 1px solid black">
            {{ formattedDate(item.aftap_date) }}
          </td>
          <td style="border: 1px solid black">
            {{ formattedDate(item.expired_date) }}
          </td>
          <td style="border: 1px solid black">2/6</td>
          <td style="border: 1px solid black">14/07/2024</td>
          <td style="border: 1px solid black">NR</td>
          <td style="border: 1px solid black">NR</td>
          <td style="border: 1px solid black">NR</td>
          <td style="border: 1px solid black">NR</td>
          <td style="border: 1px solid black">
            {{ droppingInfoBlood.hospital_blood_receivers.name }}
          </td>
          <td style="border: 1px solid black">NON NAT</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "Lampiran",
  props: {
    droppingInfoBlood: {
      type: Object,
      default: () => ({
        date: "",
        components: [],
      }),
    },
  },
  data() {
    return {
      bloodComponents: [
        { name: "PRC NAT" },
        { name: "PRC NON NAT" },
        { name: "PCL" },
        { name: "AHF" },
        { name: "TC NON NAT" },
        { name: "FFP NAT" },
        { name: "FFP NON NAT" },
        { name: "PCR-LR" },
      ],
      componentsData: {
        component_barcode: "",
        componen: "",
        blood_bag_number: "",
        blood_type_result: "",
        volume: "",
        aftap_date: "",
        expired_date: "",
      },
    };
  },
  computed: {
    filteredComponents() {
      return this.droppingInfoBlood.components.filter((item) => {
        return this.bloodComponents.some((component) =>
          item.component.includes(component.name.split(" ")[0])
        );
      });
    },
  },
  watch: {
    droppingInfoBlood: {
      immediate: true,
      deep: true,
    },
  },
  methods: {
    formattedDate(date) {
      const dateTime = new Date(date);

      return dateTime.toLocaleDateString("id-ID", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
    },
  },
};
</script>

<style scoped>
table {
  page-break-inside: avoid;
}

th {
  padding: 0px;
}

td {
  margin: 0px;
  padding: 0px;
}
</style>
